<template>
  <section>
    <navi-bar>
      {{$t('front.common.depositApply')}}
    </navi-bar>
    <ul class="chargeTab">
       <li @click="goPageByName('coinCharge')">일반 입금</li>
       <li class="on" @click="goPageByName('charge')">코인 입금</li>
    </ul>
    <template v-if="selectedCoin">
      <div class="selectWrap">
         <div class="selectBox mt30">
            <em>코인선택</em>
            <span class="name"><i></i></span>
           <select v-model="selectedCoin">
             <option v-for="(item, index) in coinList" :key="index" :value="item" :disabled="!item.depositAllEnable">{{item.coin}}</option>
           </select>
         </div>
         <input type="text" pattern="[0-9.,]+" class="numb mt30" :placeholder="$t('front.give.moneyInput')" v-model="setCurrentChargeMoney"/>
      </div>
      <div class="selectWrap">
         <div class="selectBox">
            <em>네트워크</em>
            <span class="name">{{selectedCoin.coin}}</span>
           <select v-model="selectedNetwork">
             <option v-for="(item, index) in networkList" :key="index" :value="item" :disabled="!item.depositEnable">{{item.name}}</option>
           </select>
         </div>
         <a @click="getCalculate" class="btn">계산하기</a>
      </div>
      <div class="selectBox">
         <em>입금캐시</em>
         <p class="cash">
            <img src="@/assets/img/icon_coin.svg" />
            <span>{{thousand(cashCalculate)}}</span>
         </p>
      </div>
      <div class="address">
         <em>입금 지갑 주소</em>
         <input type="text" :value="wallet.address" />
         <a><img src="@/assets_mobile/img/icon_copy.svg" @click="onCopy('.address input')"/></a>
      </div>
      <div class="coinInfo">
         <ul>
            <li>
               <em>EXpected Arrival</em>
              <span>{{thousand(selectedNetwork.minConfirm)}} network_confirmations</span>
            </li>
            <li>
               <em>EXpected Unlock</em>
              <span>{{thousand(selectedNetwork.unlockConfirm)}} network_confirmations</span>
            </li>
            <li>
               <em>최소 입금 액수</em>
              <span>{{ thousand(selectedCoin.minDepositAmount) }}</span>
            </li>
            <li>
               <em>선택한 지갑</em>
               <span>현물 지갑</span>
            </li>
         </ul>
      </div>
      <div class="btnwrap">
        <a class="ok" @click="onSubmit">{{thousand(cashCalculate)}} {{selectedCoin.coin}} {{$t('front.board.apply')}}</a>
      </div>
    </template>
    <template v-if="type == 'apply'">
      <div class="acountapply">
        <a @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_question.svg" /></a>
        <a class="acountbtn"  @click="getAcc">{{$t('front.cash.moneyCheckListG')}}</a>
      </div>
      <div class="appliwrite">
        <!--h2 class="conName">{{$t('front.give.moneyInput')}}</h2-->
        <input type="text" pattern="[0-9.,]+" class="numb" :placeholder="$t('front.give.moneyInput')" v-model="setCurrentChargeMoney"/>
        <img src="@/assets_mobile/img/icon_delete.svg" @click="setCurrentChargeMoney = ''"/>
      </div>
      <ul class="applihave">
        <li><img src="@/assets_mobile/img/icon_moneys.svg" /></li>
        <li>{{$t('front.cash.moneyHave')}}</li>
        <li> : <span>{{thousand(userData.cashAmt)}}</span>{{$t('front.common.money')}}</li>
      </ul>
      <ul class="applimoneybtn">
        <li><a @click="setMoney(10000)">+1{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(50000)">+5{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(100000)">+10{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(1000000)">+100{{$t('front.cash.manwon')}}</a></li>
      </ul>
      <div class="moneyinfowrap" v-if="moneyinfo">
        <div class="moneyinfoappli">
          <a class="close" @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
          <h3 class="applih">{{$t('front.cash.moneyCheckList')}}</h3>
          <!--div class="btnwrap">
            <a class="appli" @click="getAcc">계좌번호 신청</a>
          </div-->
          <ul class="applistep">
            <li><em>1</em><span>{{$t('front.cash.moneyCheckListA')}}</span></li>
            <li><em>2</em><span>{{$t('front.cash.moneyCheckListB')}}</span></li>
            <li><em>3</em><span>{{$t('front.cash.moneyCheckListC')}}</span></li>
            <li><em>4</em><span>{{$t('front.cash.moneyCheckListD')}}</span></li>
          </ul>
        </div>

        <!--ul class="appliinput">
          <li>보너스 금액</li>
          <li class="bbl">{{thousand(bonus)}}</li>
        </ul-->
        <!--ul class="appliinput">
          <li>보너스 혜택</li>
          <li class="btnwrap m0">
            <a class="all"><input type="checkbox" :checked="isApplyBonus === true" @click="isApplyBonus = true"/>받기</a>
            <a class="selec"><input type="checkbox" :checked="isApplyBonus === false" @click="isApplyBonus = false"/>받지않기</a>
          </li>
        </ul-->
      </div>
      <div class="moneyread">
        <h3>{{$t('front.cash.moneyCheckList')}}</h3>
        <ul>
          <li><img src="@/assets_mobile/img/icon_check.svg" /><span>{{$t('front.cash.we')}} {{$t('front.cash.moneyCheckListG')}} {{$t('front.cash.moneyCheckListH')}}</span></li>
          <li><img src="@/assets_mobile/img/icon_check.svg" /><span>{{$t('front.cash.moneyCheckListI')}}</span></li>
          <li><img src="@/assets_mobile/img/icon_check.svg" /><span>{{$t('front.cash.moneyInput')}} {{$t('front.cash.moneyCheckListK')}} {{$t('front.cash.moneyCheckListL')}} {{$t('front.cash.moneyCheckListM')}}</span></li>
        </ul>
      </div>
      <div class="btnwrap">
        <a class="ok" @click="onSubmit">{{$t('front.board.apply')}}</a>
      </div>
      <div class="applylistbtn mb100">
        <a :class="{'on' : type == 'list'}" @click="type = 'list'">{{$t('front.cash.depositCheck')}} ></a>
      </div>
      <!--div class="moneytab">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"><span>입금신청</span></a>
        <a :class="{'on' : type == 'list'}" @click="type = 'list'"><span>입금내역</span></a>
      </div-->
    </template>

    <template v-if="type == 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />

      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <a class="close">{{$t('front.board.delete')}}</a>
            <!--input type="checkbox" v-model="item.checked"-->
            <ul class="gamebetdetail textleft">
              <li class="ft13">{{computedCashStatus(item.cashStatus).text}} ({{dateFormat(item.regDate)}})</li>
              <li class="ft15 mt10">{{$t('front.board.processMoney')}}</li>
              <!--li><span>보너스금액</span>:<span class="rd">{{thousand(item.preCashAmt)}}</span></li-->
            </ul>
            <ul class="gamebetdetail textright">
              <li class="mt30 ft15"><span class="wh fb">{{thousand(item.cashAmt)}}</span> {{$t('front.common.money')}}</li>
              <li class="gr ft13 mt10">{{dateFormat(item.updDate)}}</li>
            </ul>
            <!--a :class="computedCashStatus(item.cashStatus).class">{{computedCashStatus(item.cashStatus).text}}</a-->
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>

      </div>
      <!--div class="btnwrap">
        <a class="all" @click="onAllCheck('cashList')">{{allChecked ? '전체해제': '전체선택'}}</a>
        <a class="selec" @click="onRemoveMsg">선택삭제</a>
      </div-->
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
      <div class="applylistbtn left mt10 mb100">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"> &lt; {{$t('front.common.depositApply')}}</a>
      </div>
    </template>

  </section>
</template>

<script>

import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Charge from '@/views/member/money/charge'

export default {
  name: 'charge',
  data () {
    return {
      moneyinfo: false
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Charge
  ]
}
</script>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>

<style>
.morebtn {margin-bottom: 10px !important;}
</style>
